<template>
  <LayoutManager>
    <div class="ml-main w-100" id="qa-my-learning-view">
      <b-row class="m-0">
        <b-col>
          <div class="ld-sec__tabs mt-0">
            <b-tabs content-class="mt-3" v-model="tabIndex">
              <b-tab
                :title="tab.name"
                :active="tab.active"
                v-for="(tab, index) in tabs"
                :key="index"
              >
                <div v-if="isLoading" class="cd-list__loader">
                  <div
                    class="cd-list__loader--div"
                    v-for="c in [1, 2, 3, 4]"
                    :key="c"
                  >
                    <shimmer variant="landing-course-card" />
                  </div>
                </div>
                <div class="cd-list__cards" v-else>
                  <div
                    class="cd-list__cards--achievement"
                    v-if="tab.tab_id === 'ac'"
                  >
                    <div
                      class="ld-sec__achievements"
                      v-show="allCredentials.length > 0"
                    >
                      <Achievements
                        v-for="(c, i) in allCredentials"
                        :key="i"
                        :credential="c"
                        :index="i"
                        @click="$bvModal.show('achievement-modal')"
                      />
                    </div>
                    <div
                      class="achievement-empty-sec"
                      v-if="allCredentials.length === 0"
                    >
                      <div>
                        <img
                          src="@/assets/images/not-found/achievement-not-found.svg"
                          alt="not found"
                          width="auto"
                          height="auto"
                        />
                        <h5 class="mt-2">
                          {{ $t("achievements.empty.title") }}
                        </h5>
                        <p>
                          {{ $t("achievements.empty.subtitle") }}
                        </p>
                        <b-button
                          class="btn ob-btn ob-btn-primary"
                          @click="$router.push({ path: '/course-discovery' })"
                          >{{ $t("achievements.empty.button") }}</b-button
                        >
                      </div>
                    </div>
                  </div>
                  <div class="cd-list__courses" v-if="tab.tab_id !== 'ac'">
                    <div
                      class="my-learning__card-div"
                      v-for="(course, index) in courses"
                      :key="index"
                    >
                      <CourseCard
                        :courseImage="
                          course.image_url
                            ? course.image_url
                            : require('@/assets/images/illustration/course-illustration.svg')
                        "
                        :org="course.org"
                        :courseTitle="course.title"
                        :language="language"
                        :pacing="course.pacing"
                        :courseLanguage="course.language"
                        :index="`${tab.tab_id}-course-card-${index}`"
                        :currentServerTime="currentServerTime"
                        @click="onCard(course, tab.tab_id)"
                        @orgClick="onOrgClick(course)"
                        @itemClick="onItemClick"
                        :status="
                          tab.tab_id === 'co' ? 'COMPLETED' : getType(course)
                        "
                        :course="course"
                      ></CourseCard>
                    </div>
                    <div
                      class="achievement-empty-sec"
                      v-if="courses.length === 0"
                    >
                      <div>
                        <img
                          src="@/assets/images/not-found/achievement-not-found.svg"
                          alt="not found"
                          width="auto"
                          height="auto"
                        />
                        <h5 class="mt-2">
                          {{
                            tab.tab_id === "cl"
                              ? $t("my_learning.continue_learning.empty.title")
                              : $t("my_learning.completed.empty.title")
                          }}
                        </h5>
                        <p>
                          {{
                            tab.tab_id === "cl"
                              ? $t(
                                  "my_learning.continue_learning.empty.subtitle"
                                )
                              : $t("my_learning.completed.empty.subtitle")
                          }}
                        </p>
                        <b-button
                          class="btn ob-btn ob-btn-primary"
                          @click="$router.push({ path: '/course-discovery' })"
                          >{{ $t("achievements.empty.button") }}</b-button
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </b-tab>
            </b-tabs>
          </div>
        </b-col>
      </b-row>
      <b-modal
        id="achievement-modal"
        size="lg"
        centered
        :dir="language === 'ar' ? 'rtl' : 'ltr'"
        hide-footer
      >
        <template #modal-header="{ close }">
          <div class="d-flex justify-content-end w-100">
            <img
              src="@/assets/images/close.svg"
              alt="close"
              width="auto"
              height="auto"
              class="c-pointer"
              @click="close()"
            />
          </div>
        </template>
        <div class="ac-div">
          <b-row class="m-0">
            <b-col class="p-0">
              <div class="d-flex ac-card__title align-items-start">
                <img
                  src="@/assets/images/certificates/badge-success.svg"
                  alt="success"
                  width="auto"
                  height="auto"
                  class="ac-card__badge"
                />
                <div class="ml-4 ac-card__label mr-2 mt-1">
                  <h5>
                    {{
                      getCredential.course_title
                        ? getCredential.course_title
                        : getCredential.title
                    }}
                  </h5>
                </div>
              </div>
            </b-col>
            <b-col cols="12" class="p-0">
              <ul class="ac-card__timeline">
                <li v-for="(m, i) in getCredential.microcredentials" :key="i">
                  <b-card @click="openCredential(m)">
                    <div class="d-flex align-items-center w-100">
                      <img
                        src="@/assets/images/certificates/badge-blue.svg"
                        alt="badge"
                        width="34"
                        height="43"
                        class="ml-2"
                      />
                      <div class="ac-mc__title flex-grow-1">
                        <p>{{ $t("achievements.microcredentials") }}</p>
                        <label>
                          {{ m.section_title }}
                        </label>
                      </div>
                      <div class="ac-mc__list">
                        <Button pill variant="outline-primary">{{
                          $t("general.view")
                        }}</Button>
                      </div>
                    </div>
                  </b-card>
                </li>
              </ul>
            </b-col>
          </b-row>
        </div>
      </b-modal>
      <unenroll-modal
        :courseId="currentCourse.id"
        @onUnenroll="getEnrolledCourses()"
      />
    </div>
  </LayoutManager>
</template>
<script>
import { mapGetters } from "vuex";
import moment from "moment";
import redirectToLearningSpace from "@/core/mixins/redirectToLearningSpace";

import LayoutManager from "../components/Layout/LayoutManager.vue";
import Achievements from "@/components/Achievements.vue";
import UnenrollModal from "@/components/Shared/UnenrollModal.vue";

export default {
  components: { LayoutManager, Achievements, UnenrollModal },
  mixins: [redirectToLearningSpace],
  data() {
    return {
      tabs: [
        {
          name: this.$i18n.t("my_learning.tab.my_achievements"),
          active: true,
          type: "default",
          tab_id: "ac"
        }
      ],
      tabIndex: 0,
      isLoading: true,
      currentCourse: ""
    };
  },
  computed: {
    ...mapGetters([
      "allEnrolledCourses",
      "allProfile",
      "allCredentials",
      "allCompletedCourses",
      "getCredential",
      "language",
      "currentServerTime"
    ]),
    courses() {
      return this.tabIndex === 0
        ? this.allEnrolledCourses
        : this.allCompletedCourses;
    },
    activeTab() {
      return this.tabs.find(a => a.active).tab_id;
    }
  },
  watch: {
    allProfile() {
      if (this.$route.query.tab) {
        this.tabIndex = parseInt(this.$route.query.tab);
        this.getAchievements();
      }
    }
  },
  mounted() {
    this.getAchievements();
  },
  methods: {
    onCard(c, tabId) {
      if (
        tabId === "cl" &&
        moment(c.start_date).isSameOrAfter(moment(this.currentServerTime))
      ) {
        this.navToCourseDetail(c.id);
      } else {
        this.$router.push({ name: "Self Paced", params: { id: c.id } });
      }
    },
    onOrgClick(c) {
      this.navigateToLearningSpace(c.org);
    },
    onItemClick(obj) {
      if (obj.type === "details") {
        this.navToCourseDetail(obj.course.id);
      } else if (obj.type === "unenroll") {
        this.currentCourse = obj.course;
        this.$bvModal.show("unenroll-modal");
      }
    },
    onTabClick(tab, id) {
      this.tabs.forEach(t => (t.active = false));
      tab.active = true;
      this.isLoading = true;
      if (id === "ac") {
        this.getAchievements();
      } else if (id === "cl") {
        this.getEnrolledCourses();
      } else if (id === "co") {
        this.getCompletedCourses();
      }
    },
    openWin() {
      let routeData = this.$router.resolve({
        path: `/badge-check`
      });
      window.open(routeData.href, "_blank");
    },
    openCredential(a) {
      let routeData = this.$router.resolve({
        path: `/certificate`,
        query: { key: a.key, lang: this.language }
      });
      window.open(routeData.href, "_blank");
    },
    getEnrolledCourses() {
      this.$store.dispatch("getEnrolledCourses").then(() => {
        this.isLoading = false;
      });
    },
    getCompletedCourses() {
      this.$store.dispatch("getCompletedCourses").then(() => {
        this.isLoading = false;
      });
    },
    getAchievements() {
      this.$store.dispatch("getAllCredentials").then(() => {
        this.isLoading = false;
      });
    },
    getType(course) {
      let type = "ENROLLED";
      if (course.metrics.progress_percentage > 0) {
        type = "CONTINUE_LEARNING";
      }
      return type;
    },
    navToCourseDetail(courseId) {
      this.$router.push({
        name: "Courses Detail",
        params: { id: courseId }
      });
    }
  }
};
</script>
<style lang="scss">
.ml-main {
  .ld-sec__tabs {
    margin-bottom: 20px;
    position: relative;
    .tabs {
      .nav-tabs {
        width: calc(100% - 100px);
        margin: 20px 0 0 20px;
        .nav-item {
          margin-bottom: 20px;
          margin-right: 10px;
          .nav-link {
            text-transform: uppercase;
            font-size: 13px;
            padding: 4px 12px;
            letter-spacing: 0.1px;
          }
        }
      }
    }
    .cd-list__loader {
      padding: 0 30px;
    }
    // achievement
    .cd-list__cards--achievement {
      padding: 0 0 0 30px;
      .ld-sec__achievements {
        display: flex;
        flex-wrap: wrap;
      }
      .achievement-empty-sec {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 400px;
        width: 100%;
        h5 {
          font-weight: 500;
          font-size: 18px;
          line-height: 24px;
          text-align: center;
          letter-spacing: 0.2px;
          color: #000000;
        }
        p {
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          text-align: center;
          letter-spacing: 0.2px;
          color: #a0a4a8;
        }
      }
    }
    .cd-list__courses {
      padding: 0 0 0 30px;
      display: flex;
      flex-wrap: wrap;
      .my-learning__card-div {
        width: 25%;
        margin-bottom: 20px;
      }
      .achievement-empty-sec {
        width: 100%;
      }
    }
    .ml-verify__btn {
      position: absolute;
      top: 0;
      right: 15px;
      &.ml-verify__grey {
        border-color: #a0a4a8;
        color: #a0a4a8;
      }
    }
  }
}

#achievement-modal {
  .modal-header {
    padding: 24px 24px 0;
  }
  .modal-content {
    border: 0px;
    .modal-body {
      padding: 0 1rem;
      .ac-div {
        padding: 10px 0px 20px;
        .ac-card__title {
          padding-bottom: 10px;
        }
        .ac-card__timeline {
          border-top: 0px;
        }
        .card {
          width: 100%;
          border-radius: 8px;
          border: 1px solid #acacac;
          .card-body {
            padding: 15px;
            .ac-mc__title {
              p {
                font-weight: 500;
                font-size: 14px;
                line-height: 18px;
                letter-spacing: 1.25px;
                text-transform: uppercase;
                color: #44464e;
              }
              label {
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                letter-spacing: 0.1px;
                color: #191c1d;
              }
            }
            .ac-mc__list {
              .btn-outline-primary {
                border: 1px solid #0057e0;
                box-sizing: border-box;
                padding: 10px 24px;
                min-width: auto !important;
              }
            }
          }
          &:hover {
            background: linear-gradient(
                0deg,
                rgba(0, 87, 224, 0.08),
                rgba(0, 87, 224, 0.08)
              ),
              #fbfdfd;
            border: 1px solid #0057e0;
          }
        }
      }
    }
  }
}

// rtl layout
[dir="rtl"] {
  .ml-main {
    .ld-sec__tabs {
      .cd-list__courses {
        padding: 0 30px 0 0;
        .course-card,
        .my-learning__card-div {
          float: right;
        }
      }
      .ld-sec__achievements {
        display: flex;
      }
    }
  }
  #achievement-modal {
    .modal-body {
      .ac-div {
        .card {
          .card-body {
            .ac-mc__title {
              margin: 0 35px 0 0;
              text-align: right;
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1200px) {
  .ml-main {
    .ld-sec__tabs {
      .cd-list__courses {
        .my-learning__card-div {
          width: 33.33%;
        }
      }
    }
  }
}
</style>
